@import "@/styles/_variables.scss";














































































































































































































































































































































































.iconic-player {
    width: 600px;
    height: 365px;
}

.name-overflow {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}

.media-header-text .uk-icon {
    line-height: normal;
}
